<template>
    <div class="header-banner m-0 bg-dark text-white" v-if="show && (!computedUser || computedUser && computedUser.role != 'student')">
        <!-- 
            When removing this banner, you'll need to make the following edits:

            1. Banner.vue: Set data.show: false
            2. RibbonBanner.vue: apply .bannerWrapper {margin-top: 5rem}
            3. Navigation.vue: remove style="" attribute from navbar
        -->

        <!-- Next Gen Math is looking for {{listings[0].role}}! &nbsp;
        <a href="/careers" target="_blank" rel="noopener" class="nav-link pl-0 underline">
            <u>Click here to apply.</u>
        </a> -->

        Join our Countdown to CAASPP webinar on Feb 15, 2024 at 10:00 AM PT! &nbsp;
        <a 
            href="/events" 
            target="_blank" 
            rel="noopener noreferrer" 
            class="btn btn-sm btn-secondary">
            Enroll now
        </a>
    </div>
</template>

<script>
    export default {
        data: () => ({
            show: false,
            listings: [
          {
            role: 'Per Diem Professional Development Providers',
            linkToDescription: '',
            linkToApplication: 'https://docs.google.com/forms/d/e/1FAIpQLSe0lBxkDJA9KpPt8W1NjuqJLDnBfssGNNFRiHPsPuXVaBNtcw/viewform?usp=sf_link',
          },
          {
            role: 'Instructional Video Creator',
            linkToDescription: '',
            linkToApplication: 'https://www.indeed.com/job/mathematics-teacher-6aa5239a15ff9356?_ga=2.80496983.1966785984.1672788846-400955871.1672788846',
          },
        ]

        }),
        computed: {
            computedUser() {
                if (this.$page) 
                    return this.$page.props.user;
                
                return this.user;
            },
        }
    }
// const headerNotification = document.getElementById('header-notification');
// const dontShowAgain = document.getElementById('hide-job-notification');
// const isEnabled = headerNotification ? headerNotification.getAttribute('data-enabled') : false;

// if (headerNotification) {
//     dontShowAgain.addEventListener('click', function() {
//         localStorage.setItem('hide_job_notification', true);
//         headerNotification.style.display = 'none';
//     });

//     if (!localStorage.getItem('hide_job_notification') && isEnabled) {
//         headerNotification.style.display = 'block';
//     } else {
//         headerNotification.style.display = 'none';
//     }
// }
</script>

<style lang="scss" scoped>
    .header-banner {
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        width: 100%;
        height: 36px;
        position: fixed;
        border-bottom: 1px solid #000;
        z-index: 20;
        a {
            color: #000 !important;
            font-weight: 600;
        }
    }
</style>
